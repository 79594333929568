
#list {
    max-height: 288px;
    border: 0;
}
.e-bigger #list, .e-bigger #tree1, .e-bigger #tree2 {
    height: 354px;
    overflow: auto;
}
.tree1-data, .tree2-data, .tree3-data {
    padding: 15px;
    margin-bottom: 25px;
}
#tree1, #tree2, #list {
    height: 300px;
    /* overflow: auto; */
}
.content {
    margin: 0 auto;
    border: 1px solid #dddddd;
    border-radius: 3px;
    height:370px;
    background-color: #fff;
 
}
.custom-delete::before {
    content: "\e700";
    cursor: pointer;
    color: rgba(0, 0, 0, 0.54);
    font-size: 15px;
}
.material-dark .custom-delete::before, .fabric-dark .custom-delete::before, .bootstrap-dark .custom-delete::before, .tailwind-dark .custom-delete::before,
.bootstrap5-dark .custom-delete::before {
    color: rgba(255, 255, 255, 0.54);
}
.bootstrap5 .custom-delete,
.bootstrap5-dark .custom-delete {
    margin-top: 3px;
}
.custom-delete {
    float: right;
    font-family: 'cross-circle';
}
.e-rtl .custom-delete {
    float: left;
}
.fabric.e-bigger #list.e-listview .e-list-item,
.fabric-dark.e-bigger #list.e-listview .e-list-item,
.highcontrast.e-bigger #list.e-listview .e-list-item {
    line-height: 43px;
}
.material.e-bigger #list.e-listview .e-list-item {
    line-height: 48px;
}
.bootstrap.e-bigger #list.e-listview .e-list-item {
    line-height: 47px;
}
.highcontrast .custom-delete::before {
    color: #fff;
}
.highcontrast .e-active.e-list-item .custom-delete::before {
    color: #000;
}
.bootstrap4.e-bigger #list, .bootstrap4.e-bigger #tree1, .bootstrap4.e-bigger #tree2 {
    height: 384px;
    overflow: auto;
}
.bootstrap4 #list, .bootstrap4 #tree1, .bootstrap4 #tree2 {
    height: 320px;
    overflow: auto;
}
.bootstrap4.e-bigger .e-drag-item.e-treeview .e-list-text {
    margin-left: 12px;
}
#overlay {
    position: absolute; 
    display: block; 
    width: 50%; 
    height: 10%; 
    z-index: 2; 
    cursor: pointer;
    /* background-image: url('src/images/useCase/drag-and-drop.png');  */
    background-repeat: no-repeat; 
    background-position: center; 
    background-size: 70%;
}
#imageContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
}
.custom-tree {
    overflow: auto;
}
.custom-tree .control-wrapper {
    position: relative; 
    /* min-width: 700px;  */
    max-height: 400px; 
    overflow: auto;
}
@media (max-width: 1200px) {
    .tree1-data, .tree2-data, .tree3-data {
        width: 33.33333333%;
        float: left;
    }
}
@font-face {
    font-family: 'cross-circle';
    src:url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAAKAIAAAwAgT1MvMj0gSRsAAAEoAAAAVmNtYXDnEOdVAAABiAAAADZnbHlmKuKzFgAAAcgAAABkaGVhZBRzllcAAADQAAAANmhoZWEHmQNrAAAArAAAACRobXR4B+gAAAAAAYAAAAAIbG9jYQAyAAAAAAHAAAAABm1heHABDgAkAAABCAAAACBuYW1lyFBwKAAAAiwAAAJhcG9zdJx8QW4AAASQAAAAOwABAAADUv9qAFoEAAAA//4D6gABAAAAAAAAAAAAAAAAAAAAAgABAAAAAQAA8246G18PPPUACwPoAAAAANi1qMQAAAAA2LWoxAAAAAAD6gPqAAAACAACAAAAAAAAAAEAAAACABgAAgAAAAAAAgAAAAoACgAAAP8AAAAAAAAAAQP0AZAABQAAAnoCvAAAAIwCegK8AAAB4AAxAQIAAAIABQMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAUGZFZABA5wDnAANS/2oAWgPqAJYAAAABAAAAAAAABAAAAAPoAAAAAAACAAAAAwAAABQAAwABAAAAFAAEACIAAAAEAAQAAQAA5wD//wAA5wD//wAAAAEABAAAAAEAAAAAAAAAMgAAAAIAAAAAA+oD6gALABcAAAEHFwcnByc3JzcXNwUWABc2ADcmACcGAALkg4NqhINqg4Nqg4T9iAYBG9PUARsFBf7l1NP+5QJ6hIZqg4Nqg4RqgIPu0/7lBgYBG9PUARsFBf7lAAAAABIA3gABAAAAAAAAAAEAAAABAAAAAAABAAwAAQABAAAAAAACAAcADQABAAAAAAADAAwAFAABAAAAAAAEAAwAIAABAAAAAAAFAAsALAABAAAAAAAGAAwANwABAAAAAAAKACwAQwABAAAAAAALABIAbwADAAEECQAAAAIAgQADAAEECQABABgAgwADAAEECQACAA4AmwADAAEECQADABgAqQADAAEECQAEABgAwQADAAEECQAFABYA2QADAAEECQAGABgA7wADAAEECQAKAFgBBwADAAEECQALACQBXyBjcm9zcy1jaXJjbGVSZWd1bGFyY3Jvc3MtY2lyY2xlY3Jvc3MtY2lyY2xlVmVyc2lvbiAxLjBjcm9zcy1jaXJjbGVGb250IGdlbmVyYXRlZCB1c2luZyBTeW5jZnVzaW9uIE1ldHJvIFN0dWRpb3d3dy5zeW5jZnVzaW9uLmNvbQAgAGMAcgBvAHMAcwAtAGMAaQByAGMAbABlAFIAZQBnAHUAbABhAHIAYwByAG8AcwBzAC0AYwBpAHIAYwBsAGUAYwByAG8AcwBzAC0AYwBpAHIAYwBsAGUAVgBlAHIAcwBpAG8AbgAgADEALgAwAGMAcgBvAHMAcwAtAGMAaQByAGMAbABlAEYAbwBuAHQAIABnAGUAbgBlAHIAYQB0AGUAZAAgAHUAcwBpAG4AZwAgAFMAeQBuAGMAZgB1AHMAaQBvAG4AIABNAGUAdAByAG8AIABTAHQAdQBkAGkAbwB3AHcAdwAuAHMAeQBuAGMAZgB1AHMAaQBvAG4ALgBjAG8AbQAAAAACAAAAAAAAAAoAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAIBAgEDABFjaXJjbGUtY2xvc2UtLS0wMgAAAA==) format('truetype');
    font-weight: normal;
    font-style: normal;
}

.tree2view{
    margin-top: 20px;
}
