// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';

@font-face {
    font-family: 'Livvic-Bold';
    font-weight: 800;
    src: local('Livvic-Bold'), url("../../fonts/Livvic-Bold.ttf") format('opentype');
}

@font-face {
    font-family: 'Livvic';
    font-weight: 400;
    src: local('Livvic'), url("../../fonts/Livvic-Regular.ttf") format('opentype');
}

@font-face {
    font-family: 'Livvic-SemiBold';
    font-weight: 600;
    src: local('Livvic-SemiBold'), url("../../fonts/Livvic-SemiBold.ttf") format('opentype');
}

@font-face {
    font-family: 'Livvic-Light';
    font-weight: 400;
    src: local('Livvic-Light'), url("../../fonts/Livvic-Light.ttf") format('opentype');
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.transf{
    width: 5760px; opacity: 1; transform: translate3d(0px, 0px, 0px);
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.scrollbar-container {
    .ps__rail-y {

        &:hover>.ps__thumb-y,
        &:focus>.ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }

    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container>.ps {
    &.ps--active-y>.ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;

        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }

    &.ps--scrolling-y>.ps__rail-y,
    &.ps--scrolling-x>.ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes bounce {

    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }

    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }

    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }

    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {

    0%,
    50%,
    100% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-10px);
    }

    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {

    0%,
    50%,
    100% {
        transform: translateX(0px);
    }

    25% {
        transform: translateX(-10px);
    }

    75% {
        transform: translateX(10px);
    }
}

.css-169iwlq-MuiCalendarPicker-root {
    width: 228px !important;
    margin: 0px;
}

.css-169iwlq-MuiCalendarPicker-root {
    width: 100%;
}

.react-calendar__navigation button{
    min-width: 25px;
}

.UserListEditBtn{
    border: none;
    padding: 5px 10px;
    margin-right: 15px;
    border-radius: 10px;
    background-color: rgb(125, 231, 125);
    color: white;
    cursor: pointer;
}

.StudentListEditBtn{
    border: none;
    padding: 5px 10px;
    margin-left: 15px;
    border-radius: 10px;
    background-color: rgb(130, 112, 233);
    color: white;
    cursor: pointer;
}


.UserListDelete{
    color: rgb(229, 149, 149);
    cursor: pointer;
}
.widgetSmallTitle{
    font-size: 20px;
    font-weight: bold;
}
.MuiPaper-elevation24{
    width:80% !important;
    max-width: 900px;
    height:90%;
}
.studentlistdialog{
    width: 30%;
    height: 60%;
    top:20%;
    left:35%;
    position: absolute;
}
.Assignmentinfoinput{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    border: none;
    border-bottom: 2px solid black;
  }
  .flex-container{
    display: flex;
  }
  .flex-container > div {
    // background-color: #f1f1f1;
    background-color: #ffffff;
    margin: 10px;
    padding: 20px;
    font-size: 20px;
    font-weight: bold;

  }
  .transform{
    transform: translate(-50%, 0%);
  }
  .checkclass{
    transform: translateY(-50%);
  }

